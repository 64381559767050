import * as React from "react"
import {css, jsx} from "@emotion/core"

const Heading1Styles = css`
  font-size: 96px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  @media(max-width: 767px) {
    font-size: 36px;
  }
`

const Heading2Styles = css`
  font-size: 36px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  @media(max-width: 767px) {
    font-size: 24px;
  }
`

const Heading3Styles = css`
  font-size: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  @media(max-width: 767px) {
    font-size: 16px;
  }
`

const Heading4Styles = css`
  font-size: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  @media(max-width: 767px) {
    font-size: 16px;
  }
`

const Heading5Styles = css`
  font-size: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  @media(max-width: 767px) {
    font-size: 16px;
  }
`

const Heading6Styles = css`
  font-size: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  @media(max-width: 767px) {
    font-size: 16px;
  }
`

type Props = {
  level: number
}

const Heading: React.FC<Props> = ({children, level = 2}) => {
  level = Math.max(0, Math.min(6, level))
  const tag: string = `h${level}`
  // TODO 汚いので直す
  let style = Heading2Styles
  switch (level) {
    case 1:
      style = Heading1Styles
      break;
    case 2:
      style = Heading2Styles
      break;
    case 3:
      style = Heading3Styles
      break;
    case 4:
      style = Heading4Styles
      break;
    case 5:
      style = Heading5Styles
      break;
    case 6:
      style = Heading6Styles
      break;
    default:
      break;
  }
  return jsx(
      tag,
      { css: style},
      children
  )
}

export default Heading
