import {useStaticQuery, graphql} from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "@emotion/styled"
import {BackgroundSectionQuery} from "../../types/graphql-types";
import * as React from "react";

type Props = {
  className: string
}

const BackgroundSection: React.FC<Props> = ({className = 'background-section', children}) => {
  const data: BackgroundSectionQuery = useStaticQuery(
    graphql`
      query BackgroundSection {
        desktop: file(relativePath: { eq: "GO_GREEN_PATTERNS-02.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#79c6b6`}
    >
      {children}
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100vw;
  background-position: center 70%, center, center;
  background-attachment: fixed;
`

export default StyledBackgroundSection
