import * as React from "react"
import {css} from "@emotion/core"

const ParagraphStyles = css`
  font-size: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  @media(max-width: 767px) {
    font-size: 16px;
  }
`

const Paragraph: React.FC = ({children}) => (
  <p css={ParagraphStyles}>
    {children}
  </p>
)

export default Paragraph
