import * as React from "react"
import {css} from "@emotion/core"

const MarginTopStyles = css`
  height: 150px;
  @media(max-width: 900px) {
    height: 10vh;
  }
`

const MainContentStyles = css`
  margin: auto;
  width: 900px;
  min-height: 700px;
  background-color: rgba(255,255,255,0.9);
  text-align: center;
  @media(max-width: 900px) {
    width: 100vw;
  }
`

const MarginBottomStyles = css`
  height: 80px;
  @media(max-width: 900px) {
    height: 10vh;
  }
`

const MainContent: React.FC = ({children}) => {
  return (
    <>
      <div css={MarginTopStyles}></div>
      <div css={MainContentStyles}>
        {children}
      </div>
      <div css={MarginBottomStyles}></div>
    </>
  )
}

export default MainContent
